import React from "react"

import styles from "../styles/404.module.scss"

import Layout from "../components/Layout"

const NotFound = () => {
  return (
    <Layout>
      <div className={styles.wrapper}>
        <h1>404</h1>
        <h2>Oops! Página não encontrada.</h2>
      </div>
    </Layout>
  )
}

export default NotFound
